.drawer-message-block {
  .ant-drawer-body {
    padding: 0 !important;
  }
  
  .ant-drawer-header {
    position: absolute !important;
    z-index: 1 !important;
    background: transparent !important;
    border-bottom: none;
  }
}

.message-block {
  font-family: var(--main-font-family);
  height: 100%;

  &_img {
    max-height: 100vh;
    width: 100%;
    height: 100%;

    // img {
    //   width: 100%;
    //   height: inherit;
    //   object-fit: cover;
    // }
  }

  &_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 0;

    &_text {
      text-align: center;
      padding: 0 5rem;
    }

    &__title {
      font-weight: 500;
      color: var(--main-text-color);
      font-size: 3.7rem;
      text-transform: uppercase;
    }

    &__subtitle {
      font-weight: 500;
      color: var(--main-text-color);
      margin: 3.3rem 0;
      font-size: 2.7rem;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      // text-decoration: underline;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--main-text-color);
        bottom: 0;
        left: 0;
      }
    }

    &__txt {
      font-weight: 400;
      color: var(--main-text-color);
      font-size: 2rem;
      margin-top: 3rem;
      padding: 0 5rem;
    }

    &__results {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      margin-bottom: 3rem;

      .block-result {
        position: relative;
        width: 20rem;
        text-align: center;
        font-size: 2.1rem;
        color: var(--main-text-color);

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background: var(--main-text-color);
          bottom: -0.5rem;
          left: 0;
        }

        &__project {
          &::after {
            content: "Проект";
          }
        }

        &__value {
          &::after {
            content: "Стоимость";
          }
        }

        &::after {
          position: absolute;
          bottom: -2.9rem;
          left: 0;
          width: 100%;
          font-family: var(--main-font-family);
          font-size: 1.3rem;
          color: var(--main-text-color);
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 1800px) {
  .message-block {
    &_content {
      &__title {
        font-size: 5rem;
      }

      &__subtitle {
        font-size: 4rem;
      }

      &__txt {
        font-size: 3.3rem;
      }

      &__results {
        .block-result {
          font-size: 2.8rem;
          width: 30rem;

          &::after {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
