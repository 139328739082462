.header {
  &.ant-layout-header {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  &-nav {
    a {
      margin-right: 1rem;
    }
  }
}
