.site-layout-block {
  margin: 24px 0;
  padding: 24px;

  &.withBackground {
    background: #fff;
  }

  h2.title {
    margin-bottom: 20px;
  }
}
