@font-face {
  font-family: 'PlayfairDisplay';
  src: url('./assets/fonts/PlayfairDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
	font-family: 'PlayfairDisplay';
	src: url('./assets/fonts/PlayfairDisplay-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'PlayfairDisplay';
	src: url('./assets/fonts/PlayfairDisplay-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

:root {
	--main-text-color: #8A6C4D;
	--main-font-family: 'PlayfairDisplay', sans-serif;
}

body {
	background: #f0f2f5;
}

.page-padding {
   padding: 0 50px;
}
