.search-employees {
  margin-right: 20px;
}

.employees-list {
  .ant-list-item-meta-content {
    margin: auto 5px;

    h4 {
      font-size: 1.2em;
    }
  }
}
