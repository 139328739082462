.main-page {
  background: #000;
  min-height: 100vh;
}

.table-row {
  height: 25%;
}

.level-employees {
  padding: 0 20px;
  border-bottom: 1px solid #fff; //var(--main-text-color);
  height: 100%;

  .employee-card {
    background: transparent;
    max-width: 240px;

    .ant-card-head {
      padding: 0;
    }

    .ant-card-head-title {
      overflow: visible;
      padding: 8px 0;
    }

    .employee-text {
      text-align: center;
      margin: 0;
      font-family: var(--main-font-family);
      font-weight: 500;

      &.employee-sum {
        color: #fff; //var(--main-text-color);
        font-size: 1.7rem;
      }

      &.employee-name {
        font-size: 1.7rem;
        color: #fff;
      }
    }

    .ant-card-cover {
      background: #cccccc;
      height: 255px;
    }

    .ant-card-body {
      padding: 12px;
    }
  }
}

.level-description {
  height: 100%;
  border-right: 1px solid #fff; //var(--main-text-color);
  border-bottom: 1px solid #fff; //var(--main-text-color);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: var(--main-font-family);

  h3,
  p {
    color: #fff;
  }

  h3 {
    font-size: 3rem;
  }

  p {
    font-size: 2rem;
  }
}
